<mat-card class="organizational-maintenance-card" style=" margin-right: 1vw !important;">
    <mat-card-header>
        <mat-card-title style="font-size: 16px">
            Organization Maintenance
        </mat-card-title>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="doSearch()" style="font-size: 12px !important">SEARCH/ADD
            </button>
            <button mat-raised-button color="primary" (click)="doSave()" *ngIf="!hideSave" 
            [disabled]="!formDetailedGroup.valid || !formCheckDetailedGroup.valid"
                style="font-size: 12px !important">SAVE</button>
            <button mat-raised-button color="primary" (click)="doClear()"
                style="font-size: 12px !important">CANCEL</button>
        </mat-card-actions>
    </mat-card-header>
    <mat-card-content>

        <mat-tab-group #organizationMaintenanceTabs color="accent" backgroundColor="primary"
            [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Organization">
                <ng-template matTabContent>
                    <fieldset class="field-set-container" *ngIf="isOrgSelected">
                        <legend>Search for Organization/Contractor</legend>
                        <div class="container" novalidate>
                            <form [formGroup]="formGroup" (ngSubmit)="onOrganisationSubmit(formGroup.value)"
                                class="form">

                                <mat-form-field class="form-element org-desc" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Organization Description</mat-label>
                                    <input matInput placeholder="Organization Description"
                                        formControlName="description">
                                </mat-form-field>

                                <mat-form-field class="form-element" appearance="outline" style="padding-right: 0.5vw;">
                                    <mat-label>Organization Code</mat-label>
                                    <input matInput placeholder="Organization Code" formControlName="orgCode">
                                </mat-form-field>

                                <mat-form-field class="form-element" appearance="outline" style="padding-right: 0.5vw;">
                                    <mat-label>Telephone</mat-label>
                                    <input matInput placeholder="Telephone" formControlName="telephone">
                                </mat-form-field>

                                <mat-form-field class="form-element" appearance="outline" style="padding-right: 0.5vw;">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" formControlName="city">
                                </mat-form-field>

                                <mat-form-field class="form-element" appearance="outline">
                                    <mat-label>External Key</mat-label>
                                    <input matInput placeholder="External Key" formControlName="externalkey">
                                </mat-form-field>

                                <div class="form-element">
                                    <mat-card-actions>
                                        <button mat-raised-button color="primary" type="submit" class="button"
                                            [disabled]="!formGroup.valid" style="font-size: 12px !important">Search
                                        </button>
                                        <button mat-raised-button color="primary" type="reset" class="button"
                                            (click)="doOrgClear()" [disabled]="!formGroup.valid"
                                            style="font-size: 12px !important">Clear
                                        </button>
                                        <button mat-raised-button color="primary" type="button" class="button"
                                            [disabled]="!formGroup.valid" (click)="addOrganisation()"
                                            style="font-size: 12px !important">
                                            Add Organization</button>
                                    </mat-card-actions>
                                </div>
                            </form>
                        </div>
                    </fieldset>
                    <fieldset class="field-set-results-container" *ngIf="isOrgSelected">
                        <legend>Search Results</legend>

                        <mat-card class="list-card" style="margin-right: 1vw !important">
                            <mat-card-content style="margin-left:0.3vw">
                                <div *ngIf="organizationSearching">
                                    <mat-spinner [diameter]="25" color="primary"></mat-spinner>
                                </div>

                                <div *ngIf="!organization.length && !organizationSearching" class="search-card">
                                    <h3>No Search Results</h3>
                                </div>
                                <div class="org-table-container mat-elevation-z8">
                                    <table style="width: 100% !important" mat-table
                                        [dataSource]="dataSourceOrganization" matSort
                                        (matSortChange)="sortCustData($event)" class="mat-elevation-z8">
                                        <ng-container matColumnDef="ORGANIZATION_DESC">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization
                                                Description
                                            </th>
                                            <td mat-cell *matCellDef="let org"> {{org.ORGANIZATION_DESC}}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ORGANIZATION_CD">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization Code</th>
                                            <td mat-cell *matCellDef="let org"> {{org.ORGANIZATION_CD}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="CITY">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                                            <td mat-cell *matCellDef="let org"> {{org.CITY}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="PHONE_NUMBER">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone Number</th>
                                            <td mat-cell *matCellDef="let org"> {{org.PHONE_NUMBER}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="EXTERNALKEY">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>External Key</th>
                                            <td mat-cell *matCellDef="let org"> {{org.EXTERNALKEY}} </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            [class.master-cust]="row.FromMaster" (dblclick)="handleClick(row)"
                                            (click)="singleClick(row)"
                                            [ngClass]="{hovered: row.hovered, highlighted: orgSelection.isSelected(row)}"
                                            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">></tr>
                                    </table>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </fieldset>

                    <fieldset *ngIf="!isOrgSelected">
                        <legend>Contractor Business Information</legend>
                        <div class="container" novalidate>
                            <form [formGroup]="formDetailedGroup" class="form">

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Name" formControlName="AS_ORG_DESC">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_ORG_DESC'].valid && formDetailedGroup.controls['AS_ORG_DESC'].touched">
                                        Required field
                                    </span>
                                </mat-form-field>

                                <mat-checkbox class="form-element" style="margin-left: 10px;"
                                    formControlName="AL_ORG_ACTIVE"
                                    style="font-size:1vw !important; margin-left: 0.8vw">Active</mat-checkbox>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Organization Code</mat-label>
                                    <input matInput placeholder="Organization Code" formControlName="AS_ORG_CD" 
                                        maxlength="10"  [readonly]="readonlyOrgCD">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_ORG_CD'].valid && formDetailedGroup.controls['AS_ORG_CD'].touched">
                                        Required field, must be 10 characters.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>External Key</mat-label>
                                    <input matInput placeholder="External Key" formControlName="AS_WR_EXTERNAL_KEY">
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Street Number</mat-label>
                                    <input matInput placeholder="Street Number" formControlName="AS_WR_STREETNUM">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_WR_STREETNUM'].valid && formDetailedGroup.controls['AS_WR_STREETNUM'].touched">
                                        Required field, must be numbers.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Street Name</mat-label>
                                    <input matInput placeholder="Street Name" formControlName="AS_WR_STREETNAME">
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" formControlName="AS_WR_CITY">
                                </mat-form-field>

                                <mat-form-field class="form-element one-third" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="State" formControlName="AS_WR_STATE">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_WR_STREETNUM'].valid && formDetailedGroup.controls['AS_WR_STREETNUM'].touched">
                                        Required field
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element one-third" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Zip</mat-label>
                                    <input matInput placeholder="Zip" formControlName="AS_WR_ZIP">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_WR_ZIP'].valid && formDetailedGroup.controls['AS_WR_ZIP'].touched">
                                        Required field, must be numbers and between 4 and 10 characters.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Phone</mat-label>
                                    <input matInput placeholder="Phone" formControlName="AS_WR_PHONE">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['AS_WR_PHONE'].valid && formDetailedGroup.controls['AS_WR_PHONE'].touched">
                                        Required field, must be numbers.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Email" formControlName="WR_EMAIL">
                                    <span
                                        *ngIf="!formDetailedGroup.controls['WR_EMAIL'].valid && formDetailedGroup.controls['WR_EMAIL'].touched">
                                        Required field, must be proper email.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Classification</mat-label>
                                    <mat-select formControlName="CLASSIFICATION_CD" [disabled]="!addAccred  && !isLegacy">
                                        <mat-option style="font-size: 11px;" *ngFor="let copt of classificationOpt"
                                            [value]="copt.CLASSIFICATION_CD" style="font-size: 11px;">
                                            {{copt.CLASSIFICATION_DESC}}
                                        </mat-option>
                                    </mat-select>
                                    <span
                                        *ngIf="!formDetailedGroup.controls['CLASSIFICATION_CD'].valid && formDetailedGroup.controls['CLASSIFICATION_CD'].touched">
                                        Required field
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right:0.5vw;">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="TYPE_CD">
                                        <mat-option style="font-size: 11px;" *ngFor="let opt of typeOpt"
                                            [value]="opt.TYPE_CD">
                                            {{opt.TYPE_DESC}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>License #</mat-label>
                                    <input matInput placeholder="License" formControlName="LICENSE">
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>License Expiration</mat-label>
                                    <input matInput [matDatepicker]="LICENSEEXP" placeholder="License Expiration" [disabled]="!addAccred  && !isLegacy"
                                        formControlName="LICENSEEXP">
                                    <mat-datepicker-toggle matSuffix [for]="LICENSEEXP"></mat-datepicker-toggle>
                                    <mat-datepicker #LICENSEEXP></mat-datepicker>
                                    <span
                                        *ngIf="!formDetailedGroup.controls['LICENSEEXP'].valid && formDetailedGroup.controls['LICENSEEXP'].touched">
                                        Required field
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>License Name</mat-label>
                                    <input matInput placeholder="License Name" formControlName="LICENSENAME">
                                </mat-form-field>

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Notes</mat-label>
                                    <input matInput placeholder="Notes" formControlName="NOTES">
                                </mat-form-field>
                            </form>
                        </div>
                    </fieldset>

                    <fieldset *ngIf="!isOrgSelected">
                        <legend>Contractor Check Information</legend>
                        <div class="container" novalidate>
                            <form [formGroup]="formCheckDetailedGroup" class="form">

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Name</mat-label>
                                    <input matInput placeholder="Name" formControlName="AS_PAYEE_FULLNAME">
                                    <span
                                        *ngIf="!formCheckDetailedGroup.controls['AS_PAYEE_FULLNAME'].valid && formCheckDetailedGroup.controls['AS_PAYEE_FULLNAME'].touched">
                                        Required field.
                                    </span>
                                </mat-form-field>

                                <mat-checkbox (click)="sameAddress()">Address same as above</mat-checkbox>
                                <br/>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Street Number</mat-label>
                                    <input matInput placeholder="Street Number" formControlName="AS_PAYEE_STREETNUM">
                                    <span
                                        *ngIf="!formCheckDetailedGroup.controls['AS_PAYEE_STREETNUM'].valid && formCheckDetailedGroup.controls['AS_PAYEE_STREETNUM'].touched">
                                        Required field.Must be in number
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Street Name</mat-label>
                                    <input matInput placeholder="Street Name" formControlName="AS_PAYEE_STREETNAME">
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="City" formControlName="AS_PAYEE_CITY">
                                </mat-form-field>

                                <mat-form-field class="form-element one-third" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>State</mat-label>
                                    <input matInput placeholder="State" formControlName="AS_PAYEE_STATE">
                                    <span
                                        *ngIf="!formCheckDetailedGroup.controls['AS_PAYEE_STATE'].valid && formCheckDetailedGroup.controls['AS_PAYEE_STATE'].touched">
                                        Required field.Must be 2 character
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element one-third" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Zip</mat-label>
                                    <input matInput placeholder="Zip" formControlName="AS_PAYEE_ZIP">
                                    <span
                                        *ngIf="!formCheckDetailedGroup.controls['AS_PAYEE_ZIP'].valid && formCheckDetailedGroup.controls['AS_PAYEE_ZIP'].touched">
                                        Required field, must be numbers and between 4 and 10 characters.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Address1</mat-label>
                                    <input matInput placeholder="Address1" formControlName="AS_PAYEE_ADDRESS1">
                                </mat-form-field>

                                <mat-form-field class="form-element full-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Address2</mat-label>
                                    <input matInput placeholder="Address2" formControlName="AS_PAYEE_ADDRESS2">
                                </mat-form-field>
                            </form>
                        </div>
                    </fieldset>
                </ng-template>
            </mat-tab>
            <mat-tab [disabled]="isOrgSelected && !newOrgSel" label="Users Association">
                <ng-template matTabContent>
                    <mat-card style="width: 90%">
                        <mat-card-header class="mat-header-color"
                            style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                            User Actions for {{organizationDetails?.OrganizationTab?.AS_ORG_DESC}}
                        </mat-card-header>
                        <mat-card-content>
                            <fieldset class="field-set-results-container">
                                <legend>User Actions</legend>
                                <div class="actions-container">
                                    <div class="actions-sub-container">
                                        <span class="user-actions-header">Available Contractors</span><br>
                                        <div #activeList="cdkDropList" class="box-list" cdkDropList
                                            id="Available Contractors" [cdkDropListData]="contractors"
                                            [cdkDropListConnectedTo]="[inactiveList]"
                                            (cdkDropListDropped)="drop($event)" (cdkDropEntered)="entered($event)"
                                            (cdkDropExited)="exited($event)">
                                            <div class="drag-box" [cdkDragData]="contra"
                                                *ngFor="let contra of contractors" cdkDrag>
                                                {{contra}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="actions-sub-container">
                                        <span class="user-actions-header">Associated Contractors</span><br>
                                        <div #inactiveList="cdkDropList" class="box-list" cdkDropList
                                            id="Associated Contractors" [cdkDropListData]="organizationContractors"
                                            [cdkDropListConnectedTo]="[activeList]" (cdkDropListDropped)="drop($event)">
                                            <div class="drag-box associated-contra" [cdkDragData]="orgContra"
                                                *ngFor="let orgContra of organizationContractors" cdkDrag>
                                                {{orgContra}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </mat-tab>
            <mat-tab [disabled]="isOrgSelected && !newOrgSel" label="Work Order Types">
                <ng-template matTabContent>
                    <mat-card style="width: 50%; padding-left: 25%;">
                        <mat-card-header class="mat-header-color select-all-type"
                            style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                            <mat-checkbox [(ngModel)]="select_all" (ngModelChange)="onSelectAll($event)"></mat-checkbox>
                            &nbsp;&nbsp;&nbsp;&nbsp;{{organizationDetails?.OrganizationTab?.AS_ORG_DESC}}
                        </mat-card-header>
                        <mat-card-content>
                            <form [formGroup]="selectedOrgForm">
                                <div *ngFor="let workType of organizationDetails.WorkorderTypesTab;last as last">
                                    <mat-checkbox style="margin-left: 10px;" [(ngModel)]="workType.SELECTED"
                                        checkboxPosition="before" [ngModelOptions]="{standalone: true}"
                                        style="font-size:1vw !important; margin-left: 0.8vw">
                                        {{workType.DESCRIPTION}}
                                    </mat-checkbox><br>
                                    <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
                                </div>
                            </form>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </mat-tab>
            <mat-tab [disabled]="isOrgSelected && !newOrgSel" label="Zip Codes">
                <ng-template matTabContent>
                    <fieldset class="field-set-results-container">
                        <legend>Zip Codes for {{organizationDetails?.OrganizationTab?.AS_ORG_DESC}}</legend>
                        <mat-card style="width: 45%; float: left;">
                            <mat-card-header class="mat-header-color"
                                style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                                {{organizationDetails?.OrganizationTab?.AS_ORG_DESC}}
                            </mat-card-header>
                            <mat-card-content>
                                <mat-nav-list>
                                    <mat-list-item
                                        *ngFor="let zip of organizationDetails.ZipCodesTab;last as last;  let i=index;">
                                        <a matLine>{{ zip }}</a>
                                        <button matTooltip="Delete" mat-icon-button (click)="deleteZip(zip, i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                        <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
                                    </mat-list-item>
                                    <mat-card-actions>
                                        <button mat-raised-button color="primary"
                                             (click)="exportZips()"
                                            style="font-size: 12px !important;margin-top: 2%;margin-left: 35%;">Export
                                            Zips</button>
                                    </mat-card-actions>
                                </mat-nav-list>
                            </mat-card-content>
                        </mat-card>
                        <mat-card style="width: 45%; float: right;">
                            <mat-card-header class="mat-header-color"
                                style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                                Add Zip Code
                            </mat-card-header>
                            <mat-card-content>
                                <form class="form">
                                    <mat-form-field class="form-element full-width" appearance="outline"
                                        style="padding-right: 0.5vw;">
                                        <mat-label>Zip Code</mat-label>
                                        <input matInput placeholder="Zip Code" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="addZip" maxlength="10" type="number">
                                    </mat-form-field>
                                </form>
                                <mat-card-actions>
                                    <button mat-raised-button color="primary" [disabled]="!addZip" 
                                        (click)="addZipItem()"
                                        style="font-size: 12px !important;margin-top: 2%;margin-left: 23%;">Add
                                        Zip</button>
                                    <mat-file-upload style="padding-left: 2%;" [sectionName]="'Zip'"
                                        [labelText]="'Import Zips: '" [selectButtonText]="'Choose File(s)'"
                                        [selectFilesButtonType]="'button'" [uploadButtonText]="'Import'"
                                        [uploadButtonType]="'Import'" [allowMultipleFiles]="false"
                                        [showUploadButton]="true" [customSvgIcon]="'close_custom'"
                                        [acceptedTypes]="'.csv'" (uploadClicked)="onZipUploadClicked($event)"
                                        (selectedFilesChanged)="onZipSelectedFilesChanged($event)">
                                    </mat-file-upload>
                                </mat-card-actions>
                            </mat-card-content>
                        </mat-card>
                    </fieldset>
                </ng-template>
            </mat-tab>
            <mat-tab [disabled]="isOrgSelected && !newOrgSel" label="Documents">
                <ng-template matTabContent>

                    <mat-card style="width: 75%;padding-left: 11%;" *ngIf="!docType">
                        <mat-card-header class="mat-header-color"
                            style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                            Documents for {{organizationDetails?.OrganizationTab?.AS_ORG_DESC}}
                        </mat-card-header>
                        <mat-card-content>
                            <fieldset class="field-set-results-container">
                                <legend>Documents</legend>
                                <mat-checkbox style="margin-left: 50%;" [checked]="activeDocuments"
                                    (change)="onDocumentsChange($event); false"> Show
                                    Active Documents Only</mat-checkbox>

                                <table style="width: 100% !important; margin-top: 2%;" mat-table
                                    [dataSource]="dataSourceDocuments" class="mat-elevation-z8">

                                    <ng-container matColumnDef="columnEdit">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <button matTooltip="Edit" mat-icon-button (click)="editDoc(element)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <!-- tile Column -->
                                    <ng-container matColumnDef="TITLE">
                                        <th mat-header-cell *matHeaderCellDef> Document Title </th>
                                        <td mat-cell *matCellDef="let element"> {{element.TITLE}} </td>
                                    </ng-container>

                                    <!-- desc Column -->
                                    <ng-container matColumnDef="DESCRIPTION">
                                        <th mat-header-cell *matHeaderCellDef> Document Description </th>
                                        <td mat-cell *matCellDef="let element"> {{element.DESCRIPTION}} </td>
                                    </ng-container>

                                    <!-- date Column -->
                                    <ng-container matColumnDef="LASTUPDATED">
                                        <th mat-header-cell *matHeaderCellDef> Last Updated </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.LASTUPDATED | date: 'dd/MM/yyyy'}} </td>
                                    </ng-container>

                                        <!--view doc -->
                                    <ng-container matColumnDef="columnView">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <button matTooltip="Preview" mat-icon-button (click)="viewDoc(element)" *ngIf="element.ACTIVE">
                                                <mat-icon>preview</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <!--delete doc -->
                                    <ng-container matColumnDef="columnDelete">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <button matTooltip="Delete" mat-icon-button (click)="deleteDoc(element)" *ngIf="element.IMAGE_ID && element.ACTIVE">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedDocumentsColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedDocumentsColumns;"
                                        (click)="!row.excluded && docSelection.toggle(row)"
                                        [ngStyle]="!row.ACTIVE && {'background-color': 'lightgrey'}"></tr>
                                </table>

                                <button mat-raised-button color="primary" (click)="addDocument()" style="font-size: 12px !important;margin-top: 2%;
                            margin-left: 50%;">Add Document</button>
                            </fieldset>
                        </mat-card-content>
                    </mat-card>
                    <mat-card style="width: 75%;padding-left: 11%;" *ngIf="docType">
                        <mat-card-header class="mat-header-color"
                            style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                            {{docActionName}}
                        </mat-card-header>
                        <mat-card-content>
                            <form [formGroup]="formDocGroup" class="form">
                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Document Title</mat-label>
                                    <input matInput placeholder="Document Title" formControlName="TITLE">
                                    <span
                                        *ngIf="!formDocGroup.controls['TITLE'].valid && formDocGroup.controls['TITLE'].touched">
                                        Required field, must be alphabhets.
                                    </span>
                                </mat-form-field>

                                <mat-file-upload [sectionName]="'Document'" [labelText]="'Document'"
                                    [selectButtonText]="'Choose File(s)'" [selectFilesButtonType]="'button'"
                                    [uploadButtonText]="'Submit'" [uploadButtonType]="'submit'"
                                    [allowMultipleFiles]="false" [showUploadButton]="false"
                                    [customSvgIcon]="'close_custom'" [acceptedTypes]="'.png,.doc,.docx, .jpg, .jpeg'"
                                    (uploadClicked)="onUploadClicked($event)"
                                    (selectedFilesChanged)="onSelectedFilesChanged($event)">
                                </mat-file-upload>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="padding-right: 0.5vw;">
                                    <mat-label>Document Description</mat-label>
                                    <input matInput placeholder="Document Description" formControlName="DESCRIPTION">
                                    <span
                                        *ngIf="!formDocGroup.controls['DESCRIPTION'].valid && formDocGroup.controls['DESCRIPTION'].touched">
                                        Required field, must be alphabhets.
                                    </span>
                                </mat-form-field>

                                <mat-form-field class="form-element half-width" appearance="outline"
                                    style="display: none;">
                                    <mat-label>SYSTEM IMAGES ID</mat-label>
                                    <input matInput placeholder="SYSTEM IMAGES ID" formControlName="SYSTEMIMAGES_ID">
                                </mat-form-field>

                                <mat-checkbox class="form-element" style="margin-left: 10px;" formControlName="ACTIVE"
                                    style="font-size:1vw !important; margin-left: 0.8vw">Active</mat-checkbox>
                            </form>
                            <mat-card-actions>
                                <button mat-raised-button color="primary" (click)="updateDoc()"
                                    style="font-size: 12px !important;margin-top: 2%;margin-left: 35%;">{{docActionName}}</button>
                                <button mat-raised-button color="primary" (click)="closeDocUpdate()"
                                    style="font-size: 12px !important;margin-top: 2%;">Close
                                </button>
                            </mat-card-actions>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
            </mat-tab>
                    <!-- Accreditations -------------------------------------------------------------------------------- -->
            <mat-tab label="Accreditations" [disabled]="isOrgSelected">       
                <mat-card *ngIf="!editAccred">
                    <mat-card-content>
                        <fieldset class="field-set-results-container">
                            <legend>Accreditations</legend>
                            <table mat-table [dataSource]="dataSourceAccreditations" class="mat-elevation-z8" style="width: 100%;">
                            <ng-container matColumnDef="Classification">
                                <th mat-header-cell *matHeaderCellDef>Classification</th>
                                <td mat-cell *matCellDef="let accreditations" >
                                    <ng-container *ngFor="let opt of classificationOpt">        
                                        <ng-container *ngIf="accreditations.CLASSIFICATION==opt.CLASSIFICATION_CD">
                                            {{  opt.CLASSIFICATION_DESC }}
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Number">
                                <th mat-header-cell *matHeaderCellDef>Number</th>
                                <td mat-cell *matCellDef="let accreditations">
                                {{ accreditations.LICENCENUMBER  }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Issued">
                                <th mat-header-cell *matHeaderCellDef>Issued</th>
                                <td mat-cell *matCellDef="let accreditations">                                   
                                    {{ accreditations.ISSUED | date:"MM/dd/yyyy" }}                       
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Expires">
                                <th mat-header-cell *matHeaderCellDef>Expires</th>
                                <td mat-cell *matCellDef="let accreditations">
                                {{ accreditations.EXPIRES | date:"MM/dd/yyyy" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="State">
                                <th mat-header-cell *matHeaderCellDef>State</th>
                                <td mat-cell *matCellDef="let accreditations">
                                {{ accreditations.STATE }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Specialty">
                                <th mat-header-cell *matHeaderCellDef>Specialty</th>
                                <td mat-cell *matCellDef="let accreditations">
                                {{ accreditations.SPECIALTY}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Edit">
                                <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
                                <td mat-cell *matCellDef="let accreditations; let i = index" align="center" >
                                    <button mat-icon-button matTooltip="Edit" color="accent" (click)=editaccreditations(accreditations) >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Delete">
                                <th mat-header-cell *matHeaderCellDef >Delete</th>
                                <td mat-cell *matCellDef="let accreditations; let i = index">
                                <button mat-icon-button color="warn" [disabled]="(accreditations.EXPIRES == this.organizationDetails?.OrganizationTab?.LICENSEEXP) && (accreditations.CLASSIFICATION == this.organizationDetails?.OrganizationTab?.CLASSIFICATION_CD)" 
                                matTooltip="Delete" (click)=deleteaccreditations(accreditations,i)  >
                                    <mat-icon>delete</mat-icon>
                                </button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsAccreditations"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsAccreditations;"></tr>
                            </table>
                            <br>
                        </fieldset>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button color="primary" (click)="addAccreditations()">Add Accreditations</button>
                    </mat-card-actions>
                </mat-card>
                <mat-card  *ngIf="editAccred">
                    <mat-card-header class="mat-header-color"
                        style="align-items: center; font-size:1.1vw;color:whitesmoke ;">
                        {{addOrEditText}} Accreditation
                    </mat-card-header>
                    <br/>
                    <mat-card-content>
                        <form [formGroup]="formAccredGroup" (ngSubmit)="onAccreditationSubmit()" class="form">
                            <mat-form-field class="form-element half-width" appearance="outline"
                                            style="padding-right:0.5vw;">
                                <mat-label>Type</mat-label>
                                <mat-select formControlName="CLASSIFICATION" [disabled]="!addAccred" >
                                    <mat-option style="font-size: 11px;" *ngFor="let opt of classificationOpt"
                                        [value]="opt.CLASSIFICATION_CD">
                                        {{opt.CLASSIFICATION_DESC}}
                                    </mat-option>
                                </mat-select>
                                <span
                                    *ngIf="!formAccredGroup.controls['CLASSIFICATION'].valid && formAccredGroup.controls['CLASSIFICATION'].touched">
                                    Required field
                                </span>
                            </mat-form-field>
                            
                            <mat-form-field class="form-element half-width" appearance="outline" style="padding-right: 0.5vw;">
                                <mat-label>Number</mat-label>
                                <input matInput placeholder="License Number" formControlName="LICENCENUMBER">
                                <span
                                    *ngIf="!formAccredGroup.controls['LICENCENUMBER'].valid && formAccredGroup.controls['LICENCENUMBER'].touched">
                                    Required field
                                </span>
                            </mat-form-field>

                            
                            <mat-form-field class="form-element half-width" appearance="outline"
                                style="padding-right: 0.5vw;">
                                <mat-label>License Issued</mat-label>
                                <input matInput [matDatepicker]="ISSUED" placeholder="License Issued"
                                formControlName="ISSUED">
                                <mat-datepicker-toggle matSuffix [for]="ISSUED"></mat-datepicker-toggle>
                                <mat-datepicker #ISSUED></mat-datepicker>
                            </mat-form-field>
                        
                            <mat-form-field class="form-element half-width" appearance="outline"
                                style="padding-right: 0.5vw;">
                                <mat-label >License Expiration</mat-label>
                                <input matInput  [matDatepicker]="EXPIRES" [disabled]="!addAccred" placeholder="License Expiration"
                                formControlName="EXPIRES">
                                <mat-datepicker-toggle matSuffix [for]="EXPIRES"></mat-datepicker-toggle>
                                <mat-datepicker #EXPIRES ></mat-datepicker>
                            </mat-form-field>
                    
                            <mat-form-field class="form-element half-width" appearance="outline"
                                style="padding-right: 0.5vw;">
                                <mat-label>State</mat-label>
                                <input matInput placeholder="State" formControlName="STATE">              
                            </mat-form-field>

                            <mat-form-field class="form-element half-width" appearance="outline" style="padding-right: 0.5vw;">
                                <mat-label>Specialty</mat-label>
                                <input matInput placeholder="Specialty" formControlName="SPECIALTY">
                            </mat-form-field>
                        
                        </form>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button color="primary" (click)="onAccreditationSubmit()">Save</button>
                        <button mat-raised-button color="primary" (click)="clearAccred()">Cancel</button>
                    </mat-card-actions>
                </mat-card> 

            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>